<template>
  <div class="col-12">
    <hr />
  </div>
  <div class="col-12">
    <div class="flex-wrap d-flex align-items-center">
      <div class="d-flex me-2">
        <label class="cstm-check d-flex" v-if="showSelect">
          <span class="regular-12 text-black">{{
            $t("topBar.selectAll")
          }}</span>
          <input
            type="checkbox"
            v-model="selectAllData"
            v-on:change="selectAllRow"
          />
          <span class="checkmark radio"></span>
        </label>
      </div>
      <div class="d-flex me-auto">
        <button
          class="btn btn-outline-light btn-sm"
          :disabled="selectedItems.length === 0"
          v-on:click="deleteModal"
          v-if="showDelete"
        >
          <BaseIcon name="trash" />
          <span class="text-black regular-12">{{ $t("topBar.delete") }}</span>
        </button>
        <div v-for="method of allMethods" :key="method.title">
          <div
            v-if="method?.dropdown !== undefined"
            class="d-flex no-wrap dropdown"
          >
            <button
              class="btn btn-outline-light dropdown-btn btn-sm"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              :disabled="selectedItems.length === 0 && !method.always"
            >
              <BaseIcon
                :name="method.icon !== undefined ? method.icon : 'line'"
              />
              <span class="text-black regular-12">{{ method.title }}</span>
            </button>
            <ul class="dropdown-menu">
              <li v-for="row in method.dropdown" :key="row.name">
                <button
                  class="dropdown-item"
                  :disabled="selectedItems.length === 0"
                  v-on:click="method.method(row)"
                >
                  {{ row.name }}
                </button>
              </li>
            </ul>
          </div>
          <button
            v-else
            class="btn btn-outline-light btn-sm"
            :disabled="selectedItems.length === 0 && !method.always"
            v-on:click="method.method"
          >
            <BaseIcon
              :name="method.icon !== undefined ? method.icon : 'set-active'"
            />
            <span class="text-black regular-12">{{ method.title }}</span>
          </button>
        </div>
      </div>
      <div class="d-flex me-2">
        <span v-for="btn of btns" :key="btn.title">
          <router-link
            class="btn btn-outline-light btn-sm"
            :to="btn.url"
            v-if="btn.url"
          >
            <span class="text-black regular-12">{{ btn.title }}</span>
          </router-link>
          <button
            class="btn btn-outline-light btn-sm"
            v-else-if="btn.method"
            v-on:click="btn.method"
          >
            <span class="text-black regular-12">{{ btn.title }}</span>
          </button>
        </span>
        <span>
          <div class="d-flex no-wrap dropdown">
            <button
              id="LineOption"
              class="btn btn-outline-light dropdown-btn btn-sm"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="text-black regular-12">{{
                $t("topBar.export")
              }}</span>
            </button>
            <div class="dropdown-menu">
              <a
                class="dropdown-item"
                :href="appUrl + exportUrl.replace('{type}', 'xlsx') + token"
                v-if="exportUrl"
              >
                <BaseIcon name="xlsx" type="formats" class="xlsx-icon" />
                <span class="regular-12 text-black">xlsx</span>
              </a>
              <a
                class="dropdown-item"
                :href="appUrl + exportUrl.replace('{type}', 'csv') + token"
                v-if="exportUrl"
              >
                <BaseIcon name="csv" type="formats" class="csv-icon" />
                <span class="regular-12 text-black">csv</span>
              </a>
              <a
                class="dropdown-item"
                :href="appUrl + exportUrl.replace('{type}', 'ods') + token"
                v-if="exportUrl"
              >
                <BaseIcon name="ods" type="formats" class="ods-icon" />
                <span class="regular-12 text-black">ods</span>
              </a>
              <a
                class="dropdown-item"
                :href="appUrl + exportUrl.replace('{type}', 'xls') + token"
                v-if="exportUrl"
              >
                <BaseIcon name="xls" type="formats" class="xlsx-icon" />
                <span class="regular-12 text-black">xls</span>
              </a>
              <a
                class="dropdown-item"
                :href="appUrl + exportUrl.replace('{type}', 'pdf') + token"
                v-if="exportUrl"
              >
                <BaseIcon name="pdf" type="formats" class="pdf-icon" />
                <span class="regular-12 text-black">pdf</span>
              </a>
            </div>
          </div>
        </span>
        <button
          class="btn btn-outline-light btn-sm"
          v-if="showAdd"
          v-on:click="addNewRow()"
        >
          <BaseIcon name="add" />
          <span class="text-black regular-12">{{ $t("topBar.add") }}</span>
        </button>
      </div>
      <div class="d-flex no-wrap dropdown">
        <button
          id="LineOption"
          class="btn btn-outline-light dropdown-btn btn-sm"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <BaseIcon name="line" />
          <span class="text-black regular-12">Line&nbsp;Option</span>
        </button>
        <div
          class="dropdown-menu"
          :class="{ double: showLanguages || showCurrencies }"
          aria-labelledby="LineOption"
        >
          <div class="row">
            <div
              :class="showCurrencies && showLanguages ? 'col-4' : 'col-6'"
              v-if="showCurrencies"
            >
              <form>
                <label class="cstm-check dropdown-item">
                  <span class="regular-12 text-black mx-2">{{
                    $t("topBar.allCurrency")
                  }}</span>
                  <input
                    type="radio"
                    :value="0"
                    v-model="selectedLineOptionCurrencyData"
                  />
                  <span class="checkmark mx-2"></span>
                </label>
                <label
                  class="cstm-check dropdown-item"
                  v-for="c in currencies"
                  :key="c.code"
                >
                  <span class="regular-12 text-black mx-2">{{ c.code }}</span>
                  <input
                    type="radio"
                    v-bind:value="c.code"
                    v-model="selectedLineOptionCurrencyData"
                  />
                  <span class="checkmark mx-2"></span>
                </label>
              </form>
            </div>
            <div
              :class="showCurrencies && showLanguages ? 'col-4' : 'col-6'"
              v-if="showLanguages"
            >
              <form>
                <label class="cstm-check dropdown-item">
                  <span class="regular-12 text-black mx-2">{{
                    $t("topBar.allLanguages")
                  }}</span>
                  <input
                    type="radio"
                    :value="0"
                    v-model="selectedLineOptionLanguageData"
                  />
                  <span class="checkmark mx-2"></span>
                </label>
                <label
                  class="cstm-check dropdown-item"
                  v-for="l in languages"
                  :key="l.code"
                >
                  <span class="regular-12 text-black mx-2">{{ l.name }}</span>
                  <input
                    type="radio"
                    v-bind:value="l.code"
                    v-model="selectedLineOptionLanguageData"
                  />
                  <span class="checkmark mx-2"></span>
                </label>
              </form>
            </div>
            <div
              :class="
                showCurrencies && showLanguages
                  ? 'col-4'
                  : showCurrencies || showLanguages
                  ? 'col-6'
                  : 'col-12'
              "
            >
              <form>
                <label
                  class="cstm-check dropdown-item"
                  v-for="row in lineOptions"
                  :key="row.id"
                >
                  <span class="regular-12 text-black mx-2">{{ row.name }}</span>
                  <input
                    type="radio"
                    v-bind:value="row.id"
                    v-model="selectedLineOptionData"
                  />
                  <span class="checkmark mx-2"></span>
                </label>
              </form>
            </div>
            <div class="col-12">
              <hr class="dropdown-divider mx-2" />
              <button v-on:click="editModal" class="dropdown-item regular-12">
                {{ $t("topBar.columnsCustomisation") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <hr />
  </div>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("topBar.columnsCustomisation") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`orderChange.close`)"
          ></button>
        </div>
        <div class="modal-body row">
          <div class="col-5">
            <b>{{ $t("topBar.sets") }}</b>
            <label
              class="cstm-check dropdown-item"
              v-for="row in lineOptions"
              :key="row.id"
            >
              <span class="regular-12 text-black mx-2">{{ row.name }}</span>
              <input
                type="radio"
                v-bind:value="row.id"
                v-model="lastSelectedLineOptionData"
              />
              <span class="checkmark mx-2"></span>
            </label>
            <div class="mt-3 ms-2 d-grid">
              <input
                type="text"
                class="form-control"
                :placeholder="$t(`topBar.setName`)"
                v-model="newLineOptionName"
                v-on:keyup.enter="addLineOption"
              />
              <button
                type="button"
                class="btn input-btn"
                v-on:click="addLineOption"
              >
                <BaseIcon name="plus" />
              </button>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <b>{{ $t("topBar.columns") }}</b>
              </div>
              <div class="col-6">
                <input
                  type="search"
                  class="form-control py-0"
                  v-model="filters.rows"
                  :placeholder="$t(`topBar.search`)"
                />
              </div>
            </div>
            <div class="fix-height scroll-y">
              <div v-for="(row, i) in rowsData" :key="i">
                <label
                  class="dropdown-item cstm-check"
                  v-if="
                    row.name
                      ?.toLowerCase()
                      .indexOf(filters.rows?.toLowerCase()) > -1 &&
                    row.could_hidden !== false
                  "
                >
                  <span class="regular-12 text-black mx-2">{{ row.name }}</span>
                  <input
                    type="checkbox"
                    v-model="rowsData[i].show"
                    v-on:change="rowsSelected"
                  />
                  <span class="checkmark select mx-2"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            v-on:click="editLineOption"
            v-if="lastSelectedLineOptionName"
          >
            {{
              $t(`topBar.saveAs`, {
                lastSelectedLineOptionName: lastSelectedLineOptionName,
              })
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <ConfirmModal
    ref="deleteModal"
    :title="`Delete ${
      selectedItems.length === 1 ? 'an' : selectedItems.length
    } ${selectedItems.length === 1 ? 'item' : 'items'}`"
    :text="`<ul><li>You will delete<b> ${
      selectedItems.length === 1 ? selectedItem : selectedItems.length
    }</b> ${
      selectedItems.length === 1 ? '' : 'items'
    }</li><li>You can't undo this action</li></ul><p>Are you sure you want to delete?</p>`"
    :cancel-button="` No, Keep ${
      selectedItems.length === 1 ? 'the item' : 'items'
    }`"
    :ok-button="`Yes, delete ${
      selectedItems.length === 1 ? 'the item' : 'items'
    }`"
    v-on:success="deleteEvent"
  />
</template>

<script>
import { defineAsyncComponent } from "vue";

const BaseIcon = defineAsyncComponent(() => import("../icons/BaseIcon.vue"));
import ConfirmModal from "../modals/ConfirmModal";
import http from "../../modules/http";
import { useStore } from "vuex";
import tenant from "../../modules/tenant";
const bootstrap = require("bootstrap");

export default {
  name: "TopBar",
  components: { ConfirmModal, BaseIcon },
  props: {
    rows: Array,
    lineOptions: Array,
    selectAll: Boolean,
    selectedLineOption: Number,
    items: Array,
    btns: Array,
    methods: Array,
    path: String,
    exportUrl: String,
    showDelete: {
      default() {
        return true;
      },
      type: Boolean,
    },
    showAdd: {
      default() {
        return true;
      },
      type: Boolean,
    },
    showCurrencies: {
      default() {
        return true;
      },
      type: Boolean,
    },
    showLanguages: {
      default() {
        return true;
      },
      type: Boolean,
    },
    showSelect: {
      default() {
        return true;
      },
      type: Boolean,
    },
    selectedItems: {
      default() {
        return [];
      },
      type: Array,
    },
  },

  data() {
    return {
      store: useStore(),
      newLineOptionName: "",
      selectedLineOptionLanguageData: 0,
      selectedLineOptionCurrencyData: 0,
      selectAllData: this.selectAll,
      lineOptionsData: this.lineOptions,
      rowsData: this.rows,
      selectedLineOptionData: this.selectedLineOption,
      lastSelectedLineOptionData: this.selectedLineOption,
      filters: {
        rows: "",
      },
    };
  },
  computed: {
    appUrl() {
      return tenant.getApiBaseUrl();
    },
    token() {
      return `&_token=${this.store.state.token}&line_option=${this.selectedLineOption}`;
    },
    allMethods() {
      let methods = this.store.state.listOption;
      for (const method of methods) {
        method.method = (row) => method.onClick(this.selectedItems, row);
      }
      if (this.methods !== undefined && this.methods !== null) {
        return this.methods.concat(methods);
      }
      return methods;
    },
    languages() {
      return this.store.state.languages;
    },
    currencies() {
      return this.store.state.currencies;
    },
    selectedItem() {
      if (this.selectedItems === undefined || this.selectedItems.length < 1) {
        return "";
      }
      return this.rows[0].name + " " + this.selectedItems[0][this.rows[0].key];
    },
    lastSelectedLineOptionName() {
      return this.lineOptions?.find(
        (x) => x.id === this.lastSelectedLineOptionData
      )?.name;
    },
  },
  emits: [
    "update:selectAll",
    "update:lineOptions",
    "update:selectedLineOption",
    "selectAllRow",
    "addNewRow",
    "delete",
  ],
  watch: {
    rows() {
      this.rowsData = this.rows;
    },
    lineOptions() {
      this.lineOptionsData = this.lineOptions;
    },
    lastSelectedLineOptionData() {
      this.selectedLineOptionData = this.lastSelectedLineOptionData;
    },
    selectedLineOptionData() {
      this.calcLineOptions();
    },
    selectedLineOptionLanguageData() {
      this.calcLineOptions();
    },
    selectedLineOptionCurrencyData() {
      this.calcLineOptions();
    },
    selectedLineOption() {
      this.selectedLineOptionData = this.selectedLineOption;
    },
    selectAll() {
      this.selectAllData = this.selectAll;
    },
  },
  mounted() {
    window.addEventListener("keydown", (event) => {
      if (
        event.target.nodeName !== "INPUT" &&
        event.ctrlKey &&
        event.key === "a"
      ) {
        this.selectAllData = !this.selectAllData;
        this.selectAllRow();
        event.preventDefault();
      }
    });
  },
  methods: {
    calcLineOptions() {
      let lineo = this.lineOptions?.find(
        (a) => a.id === this.selectedLineOptionData
      );
      if (lineo !== undefined) {
        for (const key in this.rowsData) {
          if (
            this.selectedLineOptionLanguageData !== 0 &&
            this.rowsData[key].language !== undefined &&
            this.rowsData[key].language !== this.selectedLineOptionLanguageData
          ) {
            this.rowsData[key].show = false;
            continue;
          }

          if (
            this.selectedLineOptionCurrencyData !== 0 &&
            this.rowsData[key].currency !== undefined &&
            this.rowsData[key].currency !== this.selectedLineOptionCurrencyData
          ) {
            this.rowsData[key].show = false;
            continue;
          }

          this.rowsData[key].show =
            lineo.rows.indexOf(this.rowsData[key].key) > -1;
        }
        this.lastSelectedLineOptionData = this.selectedLineOptionData;
      }
      this.$emit("update:selectedLineOption", this.selectedLineOptionData);
    },
    editModal() {
      let myModal = new bootstrap.Modal(this.$refs.modal, {
        backdrop: "static",
      });
      myModal.show();
    },
    deleteEvent() {
      this.$emit("delete");
    },
    editLineOption(save = true) {
      const lo = this.lineOptions.find(
        (x) => x.id === this.lastSelectedLineOptionData
      );
      lo.rows = [];
      const rows = this.rows.filter((x) => x.show);
      for (const row of rows) {
        lo.rows.push(row.key);
      }

      if (save) {
        const rows = this.rows
          .filter((x) => x.show)
          .map((e) => {
            let s = {
              id: e.id,
              is_characterics: e.is_characterics !== undefined ? 1 : 0,
              is_custom: e.is_custom !== undefined ? 1 : 0,
              is_external: e.is_external !== undefined ? 1 : 0,
            };
            if (e.language !== undefined) {
              s.language = e.language;
            }
            if (e.currency !== undefined) {
              s.currency = e.currency;
            }
            if (e.category !== undefined) {
              s.category = e.category;
            }
            if (e.warehouse !== undefined) {
              s.warehouse = e.warehouse;
            }
            return s;
          });

        let data = {
          id: this.lastSelectedLineOptionData,
          rows: rows,
        };

        http.fetch("/line-options/edit-rows", data, true);
      }
    },
    addLineOption() {
      if (this.newLineOptionName.length < 1) {
        return;
      }
      const id = this.lineOptionsData.length + 999999999;
      this.lineOptionsData.push({
        id: id,
        name: this.newLineOptionName,
        rows: [],
      });
      this.lastSelectedLineOptionData = id;
      this.$emit("update:lineOptions", this.lineOptionsData);
      this.editLineOption(false);

      const rows = this.rows
        .filter((x) => x.show)
        .map((e) => {
          return {
            id: e.id,
            is_characterics: e.is_characterics !== undefined ? 1 : 0,
            is_custom: e.is_custom !== undefined ? 1 : 0,
          };
        });

      let data = {
        name: this.newLineOptionName,
        rows: rows,
        path: this.path,
      };
      this.newLineOptionName = "";

      http.fetch("/line-options/add", data, true).then((data) => {
        const lo = this.lineOptions.find(
          (x) => x.id === this.lastSelectedLineOptionData
        );
        lo.id = data.id;
        this.lastSelectedLineOptionData = data.id;
      });
    },
    deleteModal() {
      this.$refs.deleteModal.showModal();
    },
    addNewRow() {
      this.$emit("addNewRow");
    },
    selectAllRow() {
      this.$emit("update:selectAll", this.selectAllData);
      this.$emit("selectAllRow");
    },
    rowsSelected() {
      let lineo = this.lineOptions.find(
        (a) => a.id === this.selectedLineOptionData
      );
      if (lineo !== undefined) {
        for (const key in this.rows) {
          if (this.rows[key].show) {
            if (lineo.rows.indexOf(this.rows[key].key) === -1) {
              this.selectedLineOptionData = 0;
              break;
            }
          } else {
            if (lineo.rows.indexOf(this.rows[key].key) !== -1) {
              this.selectedLineOptionData = 0;
              break;
            }
          }
        }
      }
    },
  },
};
</script>
